<template>
    <div>
        <v-container>
            <v-toolbar  flat dense>
                <c-date-picker
                class="mr-2"
                append-icon="mdi-calendar"
                :label="$t('plannerCodeRange.fromDate')"
                dense
                outlined
                v-model="plannerCodeRange.fromDate"
                hide-details="auto"
                />

                <c-date-picker
                class="mr-2"
                append-icon="mdi-calendar"
                :label="$t('plannerCodeRange.toDate')"
                dense
                outlined
                v-model="plannerCodeRange.toDate"
                hide-details="auto"/>

                <v-autocomplete
                dense 
                outlined 
                :items="typeOfGather" 
                class="mr-2"
                hide-details="auto"
                style="max-width:150px"
                v-model="typeofSelect"
                ></v-autocomplete>

                <v-autocomplete
                v-if="userInfo.User_Category =='admin'"
                dense 
                outlined 
                :label="typeofSelect"
                :items="adminChoices" 
                class="mr-2"
                hide-details="auto"
                style="max-width:150px"
                v-model="plannerSelected"
                ></v-autocomplete>

                <v-btn
                class="mr-5"
                @click="load(plannerCodeRange)"
                xxl
                :style="{ color: 'white' }"
                :disabled="loading"
                Length="5"
                color="#0D47A1"
                >{{$t(`generate.generate`)}}</v-btn>
                <!-- v-if="NationWideArr.length > 0 && items.length > 0" -->

                <v-autocomplete 
                v-if="NationWideArr.length > 0"
                dense 
                outlined 
                :items="tableSelector" 
                hide-details="auto"
                style="max-width:200px"
                v-model="tableSelected"
                @change="selectCategory()"
                ></v-autocomplete>

                <v-autocomplete 
                v-if="NationWideArr.length > 0"
                dense 
                outlined 
                :items="minimum" 
                class="ml-2"
                label="加工依頼数の下限値の指定"
                hide-details="auto"
                v-model="minimumKakou"
                @change="selectMinimum()"
                ></v-autocomplete>

   
                <!-- <v-btn
                v-if="NationWideArr.length > 0"
                class="ml-2"
                @click="generateCsv()"
                xxl
                dark
                Length="2"
                color="#0D47A1"
                >
                {{'CSV'}}

                <v-icon >mdi-file-table</v-icon>

                </v-btn> -->

                <download-excel class="ml-2"   v-if="NationWideArr.length > 0" :data="newArr"  :fields="headers"   :before-generate = "generateXL"  :name="title">
                    <v-btn
                        xxl
                        :style="{ color: 'white' }"
                        Length="2"
                        color="#0D47A1">
                    {{'XLS'}}
                        <v-icon >mdi-file-table</v-icon>
                    </v-btn>
                </download-excel>
            </v-toolbar>
        </v-container>
        <div v-if="loading == false">
            <v-container v-if="NationWideArr.length > 0">
                <!-- <v-row>
                    <v-col> -->
                        <v-simple-table fixed-header :height="tableHeight" class="TableStyle" dense v-if="tableSelected=='追加変更数'" width="1000px">
                            <thead>
                                <tr  style="background-color: #01579b;  color: white">
                                    <th  class="TableStyle text-center" style="background-color: #01579b; color: white;" colspan="7">追加変更数</th>
                                </tr>
                                <tr  style="color: white">
                                    <th class="TableStyle text-center pa-0" style="background-color: #01579b; color: white;  width:60px;">{{'順位'}}</th>
                                    <th class="TableStyle text-center pa-0" style="background-color: #01579b; color: white;  width:100px;" >{{'加工依頼数'}}</th>
                                    <th class="TableStyle text-center pa-0" style="background-color: #01579b; color: white;  width:100px;">{{'追加変更数'}}</th>
                                    <th class="TableStyle text-center pa-0" style="background-color: #01579b; color: white;  width:160px;">{{'1件あたりの追加変更数'}}</th>
                                    <th class="TableStyle text-center pa-0" style="background-color: #01579b; color: white;  width:170px;">{{'営業所名'}}</th>
                                    <th v-if="NationWideArr[0].BranchName" class="TableStyle text-center pa-0" style="background-color: #01579b; color: white;  width:170px;">{{'展示場名'}}</th>
                                    <th v-if="NationWideArr[0].EmployeeName" class="TableStyle text-center pa-0" style="background-color: #01579b; color: white;  width:130px;">{{'設計担当者名'}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,i) in NationWideArr" :key="i">
                                    <td class="TableStyle text-center pa-0">{{item.rank }}</td>
                                    <td class="TableStyle text-center pa-0">{{item.KakouIrai }}</td>
                                    <td class="TableStyle text-center pa-0">{{item.TH }}</td>
                                    <td class="TableStyle text-center pa-0">{{item.avg}}</td>
                                    <td class="TableStyle text-center pa-0">{{item.SalesOfficeName }}</td>
                                    <td v-if="NationWideArr[0].BranchName" class="TableStyle text-center pa-0">{{item.BranchName}}</td>
                                    <td v-if="NationWideArr[0].EmployeeName" class="TableStyle text-center pa-0">{{item.EmployeeName}}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-simple-table fixed-header :height="tableHeight" class="TableStyle " dense v-else-if="tableSelected=='CAD依頼数（一般プラン）'">
                            <thead>
                                <tr  >
                                    <th  class="TableStyle text-center" style="background-color: #01579b; color: white;" colspan="7">CAD依頼数（一般プラン）</th>
                                </tr>
                                <tr  >
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:60px;">{{'順位'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:100px;">{{'加工依頼数'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:100px;">{{'CAD依頼総数'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:160px;">{{' 1件あたりのCAD依頼数'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:170px;">{{'営業所名'}}</th>
                                    <th  v-if="items[0].BranchName" class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:170px;">{{'展示場名'}}</th>
                                    <th  v-if="items[0].EmployeeName" class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:130px;">{{'設計担当者名'}}</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr v-for="(item,i) in items" :key="i">
                                    <td class="TableStyle text-center pa-0">{{item.rank }}</td>
                                    <td class="TableStyle text-center pa-0">{{item.cadKakou }}</td>
                                    <td class="TableStyle text-center pa-0">{{item.cad}}</td>   
                                    <td class="TableStyle text-center pa-0">{{`${item.cadAvg=='NaN' ? '0.00' :item.cadAvg}`}}</td>
                                    <td class="TableStyle text-center pa-0">{{item.SalesOfficeName }}</td>
                                    <td  v-if="items[0].BranchName" class="TableStyle text-center pa-0">{{item.BranchName}}</td>
                                    <td  v-if="items[0].EmployeeName" class="TableStyle text-center pa-0">{{item.EmployeeName}}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-simple-table fixed-header :height="tableHeight" class="TableStyle " dense v-else-if="tableSelected=='CAD依頼数(i-smile/i-palette/HUGme)'">
                            <thead>
                                <tr>
                                    <th  class="TableStyle text-center" style="background-color: #01579b; color: white;" colspan="7">CAD依頼数(i-smile/i-palette/HUGme)</th>
                                </tr>
                                <tr>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:60px;">{{'順位'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:100px;">{{'加工依頼数'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:100px;">{{'CAD依頼総数'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:160px;">{{'1件あたりのCAD依頼数'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:170px;">{{'営業所名'}}</th>
                                    <th v-if="items2[0].BranchName" class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:170px;">{{'展示場名'}}</th>
                                    <th v-if="items2[0].EmployeeName"  class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:130px;">{{'設計担当者名'}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,i) in items2" :key="i">
                                        <td class="TableStyle text-center pa-0">{{item.rank }}</td>
                                        <td class="TableStyle text-center pa-0">{{item.cadKakou2 }}</td>
                                        <td class="TableStyle text-center pa-0">{{item.cad2}}</td>
                                        <td class="TableStyle text-center pa-0">{{`${item.cadAvg2=='NaN' ? '0.00' :item.cadAvg2}`}}</td>
                                        <td class="TableStyle text-center pa-0">{{item.SalesOfficeName }}</td>
                                        <td v-if="items2[0].BranchName" class="TableStyle text-center pa-0">{{item.BranchName}}</td>
                                        <td v-if="items2[0].EmployeeName"  class="TableStyle text-center pa-0">{{item.EmployeeName}}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-simple-table fixed-header :height="tableHeight" class="TableStyle " dense v-else-if="tableSelected=='CAD依頼数(一般プラン+i-Smile/i-Palette/HUGme)'">
                            <thead>
                                <tr>
                                    <th  class="TableStyle text-center" style="background-color: #01579b; color: white;" colspan="7">CAD依頼数(一般プラン+i-Smile/i-Palette/HUGme)</th>
                                </tr>
                                <tr>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:60px;">{{'順位'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:100px;">{{'加工依頼数'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:100px;">{{'CAD依頼総数'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:160px;">{{'1件あたりのCAD依頼数'}}</th>
                                    <th class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:170px;">{{'営業所名'}}</th>
                                    <th  v-if="items2[0].BranchName" class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:170px;">{{'展示場名'}}</th>
                                    <th  v-if="items2[0].EmployeeName" class="TableStyle text-center  pa-0" style="background-color: #01579b; color: white; width:130px;">{{'設計担当者名'}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,i) in items3" :key="i">
                                    <td class="TableStyle text-center pa-0">{{item.rank }}</td>
                                    <td class="TableStyle text-center pa-0">{{item.cadKakou3 }}</td>
                                    <td class="TableStyle text-center pa-0">{{item.cad3}}</td>
                                    <td class="TableStyle text-center pa-0">{{`${item.cadAvg3=='NaN' ? '0.00' :item.cadAvg3}`}}</td>
                                    <td class="TableStyle text-center pa-0">{{item.SalesOfficeName }}</td>
                                    <td  v-if="items2[0].BranchName" class="TableStyle text-center pa-0">{{item.BranchName}}</td>
                                    <td  v-if="items2[0].EmployeeName" class="TableStyle text-center pa-0">{{item.EmployeeName}}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
            </v-container>
        </div>
        <div v-if="loading == false">
            <div v-if="NationWideArr.length == 0 && items == 0" >
                <img class="cnter" src="../assets/Logo/Ranking.png" />
            </div>
         </div>
         <div v-else class="center" >
            <v-overlay :value="loading" color="#000000">
                <v-progress-circular
                :width="10"
                color="primary"
                indeterminate
                :size="70"
                >
                </v-progress-circular>
                <div>{{'ローディング中....'}}</div> 
            </v-overlay>
            </div>
    </div>
</template>
<script>
import moment  from 'moment'
import CDatePicker from '../components/CDatePicker.vue'
import axios from 'axios'
import Swal from "sweetalert2";
export default {
  components: { CDatePicker },
        data() {
            return {
                plannerCodeRange: {
                    fromDate: moment().subtract(365,'days').format('YYYY-MM-DD'),
                    // fromDate: moment().subtract(11,'months').startOf('month').format('YYYY-MM-DD'),
                    toDate: moment().format('YYYY-MM-DD'),
                },
                NationWideArr:[],
                items:[],items2:[],items3:[],firstProcess:[],firstProcess2:[],firstProcess3:[],
                loading:false,
                adminChoices:['ICHIJO','GC'],plannerSelected:'',
                tableSelector:['追加変更数','CAD依頼数(一般プラン+i-Smile/i-Palette/HUGme)','CAD依頼数（一般プラン）','CAD依頼数(i-smile/i-palette/HUGme)'],
                tableSelected:'追加変更数',
                minimum:[],minimumKakou:'',
                table1Hold:[],table2Hold:[],table3Hold:[],table4Hold:[],
                SalesOffice:null,
                headers:{ },
                newArr:[],title:'',
                tableHeight: window.innerHeight - 150,
                typeOfGather:['Planners','SalesOffices'],
                typeofSelect:''
            }
        },
        methods: {
            load() {
                if( parseInt(moment.duration(moment(this.plannerCodeRange.toDate,"YYYY-MM-DD").diff(moment(this.plannerCodeRange.fromDate,"YYYY-MM-DD"))).asDays()) <= 365   ){
                    if(this.userInfo.User_Category == 'admin'){
                        if(this.plannerSelected==''|| this.typeofSelect == ''){
                                alert('Select Planner first')
                                return false;
                        }
                        else if(this.plannerSelected=='GC'){
                            this.plannerCodeRange.SalesOfficeName = 'GC'
                        }
                        else{ 
                            this.plannerCodeRange.SalesOfficeName = 'ICHIJO'
                        }
                    }else if(this.userInfo.User_Category == 'Ichijo User'|| this.userInfo.User_Category == 'ICHIJO' || this.userInfo.User_Category == 'ichijo'|| this.userInfo.User_Category == 'ichijo User'){
                        this.plannerCodeRange.SalesOfficeName = 'ICHIJO'
                    }else{
                        this.plannerCodeRange.SalesOfficeName = 'GC'   
                    }
                    this.loading=true
                    this.items=[]
                    this.items2=[]
                    this.table1Hold=[]
                    this.table2Hold=[]
                    this.table3Hold=[]
                    this.table4Hold=[]
                    this.firstProcess=[]
                    this.firstProcess2=[]
                    this.firstProcess3=[]
                    this.NationWideArr=[]
                    axios.post(`${this.aws}thRanking`,this.plannerCodeRange,this.apiKey).then(res=>{
                            res.data = res.data.filter(x=>{
                                if(x.SalesOfficeName!='展示場'){
                                    return x;
                                }
                            })
                        if(this.SalesOffice != null){
                            res.data =res.data.filter(x=>{
                                return x.SalesOfficeCode == this.SalesOffice[0].SalesOfficeCode
                            })
                        }
                    let NationWide = res.data.reduce((total,item)=>{
                            if(item.SalesOfficeCode ){
                                let index = total.findIndex(y =>   y.year == item.year && y.month == item.month  )
                                if(index >-1){
                                    total[index].th += item.TH 
                                    total[index].kakou += 1,
                                    // total[index].avg = Math.round( ( total[index].th/  total[index].kakou)  * 10 ) / 10
                                    total[index].avg = this.tofixed2( (total[index].th/  total[index].kakou)) 

                                    total[index].item.push(item)
                                    return total
                                }
                                else{  
                                    total.push(
                                        {
                                        year:item.year,
                                        month:item.month,
                                        th:item.TH,
                                        kakou : 1,
                                        avg : item.TH,
                                        item:[item],
                                        }
                                    )
                                    return total
                                }
                            }else{
                                return total
                            }
                        },[])
                        NationWide.forEach(rec=>{
                            rec.ranking = rec.item.reduce((total,item)=>{
                            let index = null 
                            if(this.typeofSelect =='Planners'){
                                index = total.findIndex(y => y.EmployeeCode==item.EmployeeCode )
                            }else if(this.typeofSelect =='SalesOffices'){
                                index = total.findIndex(y => y.SalesOfficeCode==item.SalesOfficeCode )
                            }
                                if(index > -1){
                                    total[index].TH += item.TH
                                    total[index].KakouIrai += 1
                                    // total[index].avg = Math.round( ( total[index].TH/  total[index].KakouIrai)  * 10 ) / 10
                                    total[index].avg =  this.tofixed2( (total[index].TH/  total[index].KakouIrai))
                                return total
                                }
                                else{
                                    let obj ={}
                                    if(this.typeofSelect =='Planners'){
                                        obj = { 
                                            EmployeeCode:item.EmployeeCode,
                                            EmployeeName:item.employee_name,
                                            TH:item.TH,
                                            KakouIrai:1,
                                            avg : item.TH,
                                            BranchCode:item.ContractExhibitionCode,
                                            BranchName:item.ContractExhibitionName,
                                            SalesOfficeCode:item.SalesOfficeCode,
                                            SalesOfficeName:item.SalesOfficeName,
                                        }
                                    } 
                                    else if(this.typeofSelect =='SalesOffices'){
                                        obj = { 
                                            TH:item.TH,
                                            KakouIrai:1,
                                            avg : item.TH,
                                            SalesOfficeCode:item.SalesOfficeCode,
                                            SalesOfficeName:item.SalesOfficeName,
                                        }
                                    }
                                    total.push(obj)
                                    return total
                                }
                            },[])
                        })
                        NationWide[0].ranking = NationWide[0].ranking.filter((rr)=>{
                            // rr.avg=(Math.round((rr.TH/rr.KakouIrai ) * 10) / 10).toFixed(2)
                            rr.avg=(rr.TH/rr.KakouIrai ).toFixed(2)

                                return rr;
                        })
                        NationWide[0].ranking.sort((a,b)=>{
                            return a.avg - b.avg
                        })
                        let ctr = 0 
                        let holdup = NationWide[0].ranking.reduce((total,item,i)=>{
                            let index = total.findIndex(x => x.avg == item.avg)
                                if(index > -1){
                                    total[index].Planners.push(item)
                                    let obj ={}
                                if(this.typeofSelect =='Planners'){
                                    obj = { 
                                        EmployeeCode:item.EmployeeCode,
                                        EmployeeName:item.EmployeeName,
                                        TH:item.TH,
                                        KakouIrai:item.KakouIrai,
                                        avg:item.avg,
                                        BranchCode:item.BranchCode,
                                        BranchName:item.BranchName,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr
                                    }
                                } 
                                else if(this.typeofSelect =='SalesOffices'){
                                    obj = { 
                                        TH:item.TH,
                                        KakouIrai:item.KakouIrai,
                                        avg:item.avg,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr
                                    }
                                }
                                total.push(obj)
                                    return total
                                }
                                else{
                                    if(ctr <= 0){
                                        ctr=ctr+(i+1)
                                    }
                                    else{
                                        ctr=i+1
                                    }
                                    let obj ={}
                                if(this.typeofSelect =='Planners'){
                                    obj = { 
                                        Planners:[item],
                                        EmployeeCode:item.EmployeeCode,
                                        EmployeeName:item.EmployeeName,
                                        TH:item.TH,
                                        KakouIrai:item.KakouIrai,
                                        avg:item.avg,
                                        BranchCode:item.BranchCode,
                                        BranchName:item.BranchName,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr
                                    }
                                } 
                                else if(this.typeofSelect =='SalesOffices'){
                                    obj = { 
                                        Planners:[item],
                                        TH:item.TH,
                                        KakouIrai:item.KakouIrai,
                                        avg:item.avg,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr
                                    }
                                }
                                total.push(obj)
                                return total
                                }
                            },[])
                            this.NationWideArr=holdup
                            this.table1Hold =holdup
                            this.loading=false
                            this.selectCategory()
                            // console.log(this.NationWideArr)
                         })

                       
// ================================================================================================================
                    axios.post(`${this.aws}getCadRequest`,this.plannerCodeRange,this.apiKey).then(res=>{                       
                        // axios.post(`${this.aws}getSalesOffices`,this.plannerCodeRange,this.apiKey).then(r=>{
                        axios.get(`${this.aws}getSalesOffices`,this.apiKey).then(r=>{
                        if(this.SalesOffice != null){
                            r.data =r.data.filter(x=>{
                                return x.SalesOfficeCode == this.SalesOffice[0].SalesOfficeCode
                            })
                        }
                           let newR=[]
                            if(this.userInfo.User_Category == 'admin'){
                                r.data.filter(x=>{
                                    if(this.plannerSelected=='GC'){
                                        // if(x.SalesOfficeName.includes('GC') && x.SalesOfficeCode !='K10'){
                                            if(x.SalesOfficeName.includes('GC') && x.SalesOfficeCode !='K10'){
                                            newR.push(x)
                                        }
                                    }
                                    else if(this.plannerSelected=='ICHIJO'){
                                        if(!x.SalesOfficeName.includes('GC')){
                                            newR.push(x)
                                        }
                                    }
                                 })
                            }else{
                                r.data.filter(x=>{
                                    if(this.userInfo.User_Category == 'GC User'|| this.userInfo.User_Category == 'gc'|| this.userInfo.User_Category == 'GC' || this.userInfo.User_Category == 'gc User'){
                                        if(x.SalesOfficeName.includes('GC') && x.SalesOfficeCode !='K10' ){
                                            newR.push(x)
                                        }
                                    }
                                    else if(this.userInfo.User_Category == 'ICHIJO'|| this.userInfo.User_Category == 'ichijo' || this.userInfo.User_Category == 'Ichijo User'|| this.userInfo.User_Category == 'ichijo User'){
                                        if(!x.SalesOfficeName.includes('GC')){
                                            newR.push(x)
                                        }
                                    }
                                })
                            }
                        let newMerge=this.test(res.data)
                        newMerge.forEach((cadData)=>{
                            let obj= {
                                EmployeeCode:'',
                                EmployeeName:'',
                                BranchCode:'',
                                BranchName:'',
                                SalesOfficeCode:'',
                                SalesOfficeName:'',
                                ConstructionTypeName:'',
                                KakouIrai:0,
                                cad:0,
                                cadKakou:0,
                                cadAvg:0,
                            }
                            let cadlength1=[]
                            for(let i=0;i<=cadData.length-1;i++){   
                                obj.EmployeeCode =cadData[0].EmployeeCode
                                    newR.filter(d=>{
                                        if(cadData[0].EmployeeCode == d.EmployeeCode){
                                            obj.EmployeeName =d.EmployeeName
                                            obj.BranchCode =d.ContractExhibitionCode
                                            obj.BranchName =d.ContractExhibitionName
                                            obj.SalesOfficeCode =d.SalesOfficeCode
                                            obj.SalesOfficeName =d.SalesOfficeName
                                        }
                                    })

                                obj.ConstructionTypeName =cadData[i].ConstructionTypeName
                                if(cadData[i].ConstructionCode){
                                    obj.KakouIrai +=1
                                }
                                // if( !cadData[i].ConstructionTypeName.includes('i-smile')  &&
                                //     !cadData[i].ConstructionTypeName.includes('i-palette') &&
                                //     !cadData[i].ConstructionTypeName.includes('HUGme')   ){
                                //     obj.cad+=parseInt(cadData[i].cad ? cadData[i].cad:0)
                                //     cadlength1.push(cadData[i])
                                //     obj.cadKakou+=1
                                // }
                                
                                if((!cadData[i].ConstructionTypeName.includes('i-smile') || cadData[i].ConstructionTypeName === 'i-smile＋')  &&
                                    !cadData[i].ConstructionTypeName.includes('i-palette') &&
                                    !cadData[i].ConstructionTypeName.includes('HUGme')   ){
                                    obj.cad+=parseInt(cadData[i].cad ? cadData[i].cad:0)
                                    cadlength1.push(cadData[i])
                                    obj.cadKakou+=1
                                }
                                if(this.typeofSelect =='Planners'){
                                    obj.cadAvg =  this.tofixed2((obj.cad / cadlength1.length))
                                }
                            } 
                            if(obj.SalesOfficeCode !='' && obj.SalesOfficeName !='展示場'){  
                                this.firstProcess.push(obj)
                            }      
                          
                    })

                    if(this.typeofSelect =='SalesOffices'){
                        this.firstProcess=this.firstProcess.reduce((total,item)=>{
                            let index = total.findIndex(x => x.SalesOfficeCode == item.SalesOfficeCode)
                            if(index > -1){
                                total[index].KakouIrai += item.KakouIrai
                                total[index].cad += item.cad
                                total[index].cadKakou += item.cadKakou
                                total[index].cadAvg  = this.tofixed2((total[index].cad/ total[index].cadKakou))
                                return total
                            }else{
                                total.push({
                                    SalesOfficeCode:item.SalesOfficeCode,
                                    SalesOfficeName:item.SalesOfficeName,
                                    KakouIrai: item.KakouIrai,
                                    cad : item.cad,
                                    cadKakou:item.cadKakou,
                                    cadAvg: this.tofixed2((item.cad/ item.cadKakou))
                                })
                                return total
                            }
                        },[])
                    }
                    this.firstProcess.sort((a, b) => {
                        if (isNaN(a.cadAvg) && !isNaN(b.cadAvg)) {
                            return -1;
                        } else if (!isNaN(a.cadAvg) && isNaN(b.cadAvg)) {
                            return 1;
                        } else {
                            return a.cadAvg - b.cadAvg;
                        }
                    });
                    let ctr = 0 
                    this.items= this.firstProcess.reduce((total,item,i)=>{
                            let index = total.findIndex(x => x.cadAvg == item.cadAvg)
                                if(index > -1){
                                 let obj={}
                                    if(this.typeofSelect =='Planners'){
                                    obj = { 
                                        EmployeeCode:item.EmployeeCode,
                                        EmployeeName:item.EmployeeName,
                                        cad:item.cad,
                                        cadAvg:item.cadAvg,
                                        cadKakou:item.cadKakou,
                                        BranchCode:item.BranchCode,
                                        BranchName:item.BranchName,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr,
                                        KakouIrai:item.KakouIrai
                                    }
                                } 
                                else if(this.typeofSelect =='SalesOffices'){
                                    obj = { 
                                        cad:item.cad,
                                        cadAvg:item.cadAvg,
                                        cadKakou:item.cadKakou,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr,
                                        KakouIrai:item.KakouIrai
                                    }
                                }
                                total.push(obj)
                                    return total
                                }
                                else{
                                   
                            if(ctr <= 0){
                                ctr=ctr+(i+1)
                            }
                            else{
                                ctr=i+1
                            }
                            let obj={}
                                 if(this.typeofSelect =='Planners'){
                                    obj = { 
                                        EmployeeCode:item.EmployeeCode,
                                        EmployeeName:item.EmployeeName,
                                        cad:item.cad,
                                        cadAvg:item.cadAvg,
                                        cadKakou:item.cadKakou,
                                        BranchCode:item.BranchCode,
                                        BranchName:item.BranchName,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr,
                                        KakouIrai:item.KakouIrai
                                    }
                                } 
                                else if(this.typeofSelect =='SalesOffices'){
                                    obj = { 
                                        cad:item.cad,
                                        cadAvg:item.cadAvg,
                                        cadKakou:item.cadKakou,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr,
                                        KakouIrai:item.KakouIrai
                                    }
                                }
                                total.push(obj)
                            return total
                                }
                            },[])
                            this.table2Hold =this.items
// ------------------------------------------------------------
                    newMerge.forEach((cadData2)=>{
                        let obj= {
                            EmployeeCode:'',
                            EmployeeName:'',
                            BranchCode:'',
                            BranchName:'',
                            SalesOfficeCode:'',
                            SalesOfficeName:'',
                            ConstructionTypeName:'',
                            KakouIrai:0,
                            cad2:0,
                            cadKakou2:0,
                            cadAvg2:0,
                            denki:0,
                            externalPers:0,
                            HP:0,
                            siWaku:0,
                            siJiku:0
                        }  
                        let cadlength2=[]
                    for(let i=0;i<=cadData2.length-1;i++){   
                        obj.EmployeeCode =cadData2[0].EmployeeCode
                            newR.filter(d=>{
                                if(cadData2[0].EmployeeCode == d.EmployeeCode){
                                    obj.EmployeeName =d.EmployeeName
                                    obj.BranchCode =d.ContractExhibitionCode
                                    obj.BranchName =d.ContractExhibitionName
                                    obj.SalesOfficeCode =d.SalesOfficeCode
                                    obj.SalesOfficeName =d.SalesOfficeName
                                }
                            })
                            obj.ConstructionTypeName =cadData2[i].ConstructionTypeName
                            if(cadData2[i].ConstructionCode){
                                obj.KakouIrai +=1
                            }
                            // if(cadData2[i].ConstructionTypeName.includes('i-smile')||cadData2[i].ConstructionTypeName.includes('i-palette')||cadData2[i].ConstructionTypeName.includes('HUGme')){
                            if(!cadData2[i].ConstructionTypeName.includes('i-smile＋')||cadData2[i].ConstructionTypeName.includes('i-palette')||cadData2[i].ConstructionTypeName.includes('HUGme')){
                                obj.cad2+=parseInt(cadData2[i].cad ? cadData2[i].cad:0)
                                cadlength2.push(cadData2[i])
                                obj.cadKakou2+=1
                            }
                        } 
                            if(this.typeofSelect =='Planners'){
                                    obj.cadAvg2 =  this.tofixed2((obj.cad2 / cadlength2.length))
                                }

                            if(obj.SalesOfficeCode !='' && obj.cadKakou2 > 0 && obj.SalesOfficeName !='展示場'){  
                                this.firstProcess2.push(obj)
                            } 
                    })     
                    if(this.typeofSelect =='SalesOffices'){
                        this.firstProcess2=this.firstProcess2.reduce((total,item)=>{
                            let index = total.findIndex(x => x.SalesOfficeCode == item.SalesOfficeCode)
                            if(index > -1){
                                total[index].KakouIrai += item.KakouIrai
                                total[index].cad2 += item.cad2
                                total[index].cadKakou2 += item.cadKakou2
                                total[index].cadAvg2  = this.tofixed2((total[index].cad2/ total[index].cadKakou2))
                                return total
                            }else{
                                total.push({
                                    SalesOfficeCode:item.SalesOfficeCode,
                                    SalesOfficeName:item.SalesOfficeName,
                                    KakouIrai: item.KakouIrai,
                                    cad2: item.cad2,
                                    cadKakou2:item.cadKakou2,
                                    cadAvg2:this.tofixed2((item.cad2/ item.cadKakou2))
                                })
                                return total
                            }
                        },[])
                    }
                    this.firstProcess2.sort((a,b)=>{
                        return a.cadAvg2 - b.cadAvg2
                    })
                    let ctr2 = 0 
                    this.items2= this.firstProcess2.reduce((total,item,i)=>{
                            let index = total.findIndex(x => x.cadAvg2 == item.cadAvg2)
                                if(index > -1){
                                    let obj={}
                                    if(this.typeofSelect =='Planners'){
                                    obj = { 
                                            EmployeeCode:item.EmployeeCode,
                                            EmployeeName:item.EmployeeName,
                                            cad2:item.cad2,
                                            cadAvg2:item.cadAvg2,
                                            cadKakou2:item.cadKakou2,
                                            BranchCode:item.BranchCode,
                                            BranchName:item.BranchName,
                                            SalesOfficeCode:item.SalesOfficeCode,
                                            SalesOfficeName:item.SalesOfficeName,
                                            rank:ctr2,
                                            KakouIrai:item.KakouIrai
                                        }
                                    } 
                                    else if(this.typeofSelect =='SalesOffices'){
                                        obj = { 
                                            cad2:item.cad2,
                                            cadAvg2:item.cadAvg2,
                                            cadKakou2:item.cadKakou2,
                                            SalesOfficeCode:item.SalesOfficeCode,
                                            SalesOfficeName:item.SalesOfficeName,
                                            rank:ctr2,
                                            KakouIrai:item.KakouIrai
                                        }
                                    }
                                    total.push(obj)
                                    return total
                                }
                                else{
                                    if(ctr2 <= 0){
                                        ctr2=ctr2+(i+1)
                                    }
                                    else{
                                        ctr2=i+1
                                    }
                                let obj={}
                                    if(this.typeofSelect =='Planners'){
                                    obj = { 
                                            EmployeeCode:item.EmployeeCode,
                                            EmployeeName:item.EmployeeName,
                                            cad2:item.cad2,
                                            cadAvg2:item.cadAvg2,
                                            cadKakou2:item.cadKakou2,
                                            BranchCode:item.BranchCode,
                                            BranchName:item.BranchName,
                                            SalesOfficeCode:item.SalesOfficeCode,
                                            SalesOfficeName:item.SalesOfficeName,
                                            rank:ctr2,
                                            KakouIrai:item.KakouIrai
                                        }
                                    } 
                                    else if(this.typeofSelect =='SalesOffices'){
                                        obj = { 
                                            cad2:item.cad2,
                                            cadAvg2:item.cadAvg2,
                                            cadKakou2:item.cadKakou2,
                                            SalesOfficeCode:item.SalesOfficeCode,
                                            SalesOfficeName:item.SalesOfficeName,
                                            rank:ctr2,
                                            KakouIrai:item.KakouIrai
                                        }
                                    }
                                    total.push(obj)
                                     return total
                                }
                            },[])
                    this.table3Hold =this.items2
// ------------------------------------------------------------
                    newMerge.forEach((cadData3)=>{
                        let obj= {
                            EmployeeCode:'',
                            EmployeeName:'',
                            BranchCode:'',
                            BranchName:'',
                            SalesOfficeCode:'',
                            SalesOfficeName:'',
                            ConstructionTypeName:'',
                            KakouIrai:0,
                            cad3:0,
                            cadKakou3:0,
                            cadAvg3:0,
                            denki:0,
                            externalPers:0,
                            HP:0,
                            siWaku:0,
                            siJiku:0
                        }  
                        let cadlength3=[]
                        for(let i=0;i<=cadData3.length-1;i++){   
                            obj.EmployeeCode =cadData3[0].EmployeeCode
                                newR.filter(d=>{
                                    if(cadData3[0].EmployeeCode == d.EmployeeCode){
                                        obj.EmployeeName =d.EmployeeName
                                        obj.BranchCode =d.ContractExhibitionCode
                                        obj.BranchName =d.ContractExhibitionName
                                        obj.SalesOfficeCode =d.SalesOfficeCode
                                        obj.SalesOfficeName =d.SalesOfficeName
                                    }
                                })
                                obj.ConstructionTypeName =cadData3[i].ConstructionTypeName
                                if(cadData3[i].ConstructionCode){
                                    obj.KakouIrai +=1
                                }
                                    obj.cad3+=parseInt(cadData3[i].cad ? cadData3[i].cad:0)
                                    cadlength3.push(cadData3[i])
                                    obj.cadKakou3+=1
                        } 
                            if(this.typeofSelect =='Planners'){
                                obj.cadAvg3 =  this.tofixed2((obj.cad3 / cadlength3.length))
                            }
                            if(obj.SalesOfficeCode !='' && obj.cadKakou3 > 0 && obj.SalesOfficeName !='展示場'){  
                                this.firstProcess3.push(obj)
                            } 
                    })     
                    if(this.typeofSelect =='SalesOffices'){
                        this.firstProcess3=this.firstProcess3.reduce((total,item)=>{
                            let index = total.findIndex(x => x.SalesOfficeCode == item.SalesOfficeCode)
                            if(index > -1){
                                total[index].KakouIrai += item.KakouIrai
                                total[index].cad3 += item.cad3
                                total[index].cadKakou3 += item.cadKakou3
                                total[index].cadAvg3  = this.tofixed2((total[index].cad3/ total[index].cadKakou3))
                                return total
                            }else{
                                total.push({
                                    SalesOfficeCode:item.SalesOfficeCode,
                                    SalesOfficeName:item.SalesOfficeName,
                                    KakouIrai: item.KakouIrai,
                                    cad3: item.cad3,
                                    cadKakou3:item.cadKakou3,
                                    cadAvg3:this.tofixed2((item.cad3/ item.cadKakou3))
                                })
                                return total
                            }
                        },[])
                    }
                    this.firstProcess3.sort((a,b)=>{
                        return a.cadAvg3 - b.cadAvg3
                    })
                    let ctr3 = 0 
                    this.items3= this.firstProcess3.reduce((total,item,i)=>{
                            let index = total.findIndex(x => x.cadAvg3 == item.cadAvg3)
                                if(index > -1){
                                    let obj={}
                                    if(this.typeofSelect =='Planners'){
                                    obj = { 
                                        EmployeeCode:item.EmployeeCode,
                                        EmployeeName:item.EmployeeName,
                                        cad3:item.cad3,
                                        cadAvg3:item.cadAvg3,
                                        cadKakou3:item.cadKakou3,
                                        BranchCode:item.BranchCode,
                                        BranchName:item.BranchName,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr3,
                                        KakouIrai:item.KakouIrai
                                        }
                                    } 
                                    else if(this.typeofSelect =='SalesOffices'){
                                        obj = { 
                                        cad3:item.cad3,
                                        cadAvg3:item.cadAvg3,
                                        cadKakou3:item.cadKakou3,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr3,
                                        KakouIrai:item.KakouIrai
                                        }
                                    }
                                    total.push(obj)
                                    return total
                                }
                                else{
                                
                            if(ctr3 <= 0){
                                ctr3=ctr3+(i+1)
                            }
                            else{
                                ctr3=i+1
                            }
                            let obj={}
                                    if(this.typeofSelect =='Planners'){
                                    obj = { 
                                        EmployeeCode:item.EmployeeCode,
                                        EmployeeName:item.EmployeeName,
                                        cad3:item.cad3,
                                        cadAvg3:item.cadAvg3,
                                        cadKakou3:item.cadKakou3,
                                        BranchCode:item.BranchCode,
                                        BranchName:item.BranchName,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr3,
                                        KakouIrai:item.KakouIrai
                                        }
                                    } 
                                    else if(this.typeofSelect =='SalesOffices'){
                                        obj = { 
                                        cad3:item.cad3,
                                        cadAvg3:item.cadAvg3,
                                        cadKakou3:item.cadKakou3,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr3,
                                        KakouIrai:item.KakouIrai
                                        }
                                    }
                                    total.push(obj)
                                return total
                                }
                            },[])
                    this.table4Hold =this.items3

                    })
                })
             
            }else{
                Swal.fire({
                    icon: "warning",
                    title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
                    text: `${this.$i18n.locale =='ja'?' 開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' :'Please re-enter so that the period from the start date to the end date is within one year.!'}`,
                });
                return false;
            }
            },
            test(data){
              let planner=[]
              let test=[]
              data.forEach(x=>{
                if(x.EmployeeCode != null ){
                    let index = planner.findIndex(y=> y.EmployeeCode==x.EmployeeCode)
                        if(index==-1){
                            planner.push({EmployeeCode:x.EmployeeCode})
                        }
                }   
              })
              planner.forEach(rec=>{
                let arr=[]
                data.forEach(rec1=>{ 
                  if(rec.EmployeeCode == rec1.EmployeeCode){
                    arr.push(rec1)
                  }
                })
                test.push(arr)
              })
              return(test)
            },
            tofixed2(num){
                    // return ( Math.round((num ) * 10) / 10).toFixed(2)
                    return num.toFixed(2)
            },
            avg(num){
                let total = num.TH/num.KakouIrai
              return (Math.round((total ) * 10) / 10).toFixed(2)
            },
            selectCategory(){
                this.minimumKakou=''
                this.minimum =[]
                this.NationWideArr=this.table1Hold
                this.items  = this.table2Hold
                this.items2  = this.table3Hold
                this.items3  = this.table4Hold
                if(this.tableSelected == '追加変更数'){
                    let arr = this.NationWideArr.reduce((total,item)=>{
                            let index = total.findIndex(x=> x == item.KakouIrai)
                            if(index == -1){
                                total.push( item.KakouIrai)
                                return total
                            }else{
                                return total
                            }
                    },[]).sort((a,b)=>{
                        return b - a;
                    })
                    for(let i=0; i <= arr[0]; i++){
                        this.minimum.push(i)
                    }
                }else{
                    let arr = this.items3.reduce((total,item)=>{
                            let index = total.findIndex(x=> x == item.cadKakou3)
                            if(index == -1){
                                total.push( item.cadKakou3)
                                return total
                            }else{
                                return total
                            }
                    },[]).sort((a,b)=>{
                        return b - a;
                    })
                    for(let i=0; i <= arr[0]; i++){
                        this.minimum.push(i)
                    }
                }
            },
            selectMinimum(){
                if(this.tableSelected == '追加変更数'){
                  this.NationWideArr=this.table1Hold.filter((item)=>{
                        if( item.KakouIrai >= this.minimumKakou){
                            return {
                                EmployeeCode:item.EmployeeCode,
                                EmployeeName:item.EmployeeName,
                                cad:item.cad,
                                cadAvg:item.cadAvg,
                                cadKakou:item.cadKakou,
                                BranchCode:item.BranchCode,
                                BranchName:item.BranchName,
                                SalesOfficeCode:item.SalesOfficeCode,
                                SalesOfficeName:item.SalesOfficeName,
                                KakouIrai:item.KakouIrai
                            }
                        }
                    })
                    this.NationWideArr =this.NationWideArr.sort((a,b)=>{
                        return a.cadAvg - b.cadAvg
                    })
                    let ctr = 0 
                    this.NationWideArr = this.NationWideArr.reduce((total,item,i)=>{
                            let index = total.findIndex(x => x.avg == item.avg)
                                if(index > -1){
                                    // total[index].Planners.push(item)
                                    total.push({
                                        EmployeeCode:item.EmployeeCode,
                                        EmployeeName:item.EmployeeName,
                                        TH:item.TH,
                                        KakouIrai:item.KakouIrai,
                                        avg:item.avg,
                                        BranchCode:item.BranchCode,
                                        BranchName:item.BranchName,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr
                                    })
                                    return total
                                }
                                else{
                                    if(ctr <= 0){
                                        ctr=ctr+(i+1)
                                    }
                                    else{
                                        ctr=i+1
                                    }

                                    total.push({
                                        // Planners:[item],
                                        EmployeeCode:item.EmployeeCode,
                                        EmployeeName:item.EmployeeName,
                                        TH:item.TH,
                                        KakouIrai:item.KakouIrai,
                                        avg:item.avg,
                                        BranchCode:item.BranchCode,
                                        BranchName:item.BranchName,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr
                                    })
                                    return total
                                }
                            },[])
                }
                else if(this.tableSelected =='CAD依頼数（一般プラン）'){
                    // this.items=[]
                    this.items  = this.table2Hold.filter((item)=>{
                        if( item.cadKakou >= this.minimumKakou){
                            return {
                                    EmployeeCode:item.EmployeeCode,
                                    EmployeeName:item.EmployeeName,
                                    cad:item.cad,
                                    cadAvg:item.cadAvg,
                                    cadKakou:item.cadKakou,
                                    BranchCode:item.BranchCode,
                                    BranchName:item.BranchName,
                                    SalesOfficeCode:item.SalesOfficeCode,
                                    SalesOfficeName:item.SalesOfficeName,
                                    KakouIrai:item.KakouIrai
                            }
                        }
                    })
                    this.items.sort((a,b)=>{
                            return a.cadAvg - b.cadAvg
                    })
                    let ctr = 0 
                    this.items= this.items.reduce((total,item,i)=>{
                            let index = total.findIndex(x => x.cadAvg == item.cadAvg)
                                if(index > -1){
                                    total.push({
                                        EmployeeCode:item.EmployeeCode,
                                        EmployeeName:item.EmployeeName,
                                        cad:item.cad,
                                        cadAvg:item.cadAvg,
                                        cadKakou:item.cadKakou,
                                        BranchCode:item.BranchCode,
                                        BranchName:item.BranchName,
                                        SalesOfficeCode:item.SalesOfficeCode,
                                        SalesOfficeName:item.SalesOfficeName,
                                        rank:ctr,
                                        KakouIrai:item.KakouIrai
                                    })
                                    return total
                                }
                                else{
                                   
                            if(ctr <= 0){
                            ctr=ctr+(i+1)
                            }
                            else{
                                ctr=i+1
                            }
                            total.push({
                                EmployeeCode:item.EmployeeCode,
                                EmployeeName:item.EmployeeName,
                                cad:item.cad,
                                cadAvg:item.cadAvg,
                                cadKakou:item.cadKakou,
                                BranchCode:item.BranchCode,
                                BranchName:item.BranchName,
                                SalesOfficeCode:item.SalesOfficeCode,
                                SalesOfficeName:item.SalesOfficeName,
                                rank:ctr,
                                KakouIrai:item.KakouIrai
                            })
                            return total
                        }
                    },[])

                }
                else if(this.tableSelected == 'CAD依頼数(i-smile/i-palette/HUGme)'){
                    this.items2  = this.table3Hold.filter((item)=>{
                        if( item.cadKakou2 >= this.minimumKakou){
                            return {
                                    EmployeeCode:item.EmployeeCode,
                                    EmployeeName:item.EmployeeName,
                                    cad2:item.cad2,
                                    cadAvg2:item.cadAvg2,
                                    cadKakou2:item.cadKakou2,
                                    BranchCode:item.BranchCode,
                                    BranchName:item.BranchName,
                                    SalesOfficeCode:item.SalesOfficeCode,
                                    SalesOfficeName:item.SalesOfficeName,
                                    KakouIrai:item.KakouIrai
                            }
                        }
                    })

                    this.items2.sort((a,b)=>{
                            return a.cadAvg2 - b.cadAvg2
                    })

                    let ctr2 = 0 

                    this.items2= this.items2.reduce((total,item,i)=>{
                    let index = total.findIndex(x => x.cadAvg2 == item.cadAvg2)
                        if(index > -1){
                            total.push({
                                EmployeeCode:item.EmployeeCode,
                                EmployeeName:item.EmployeeName,
                                cad2:item.cad2,
                                cadAvg2:item.cadAvg2,
                                cadKakou2:item.cadKakou2,
                                BranchCode:item.BranchCode,
                                BranchName:item.BranchName,
                                SalesOfficeCode:item.SalesOfficeCode,
                                SalesOfficeName:item.SalesOfficeName,
                                rank:ctr2,
                                KakouIrai:item.KakouIrai
                            })
                            return total
                        }
                        else{
                            if(ctr2 <= 0){
                                ctr2=ctr2+(i+1)
                            }
                            else{
                                ctr2=i+1
                            }
                            total.push({
                                EmployeeCode:item.EmployeeCode,
                                EmployeeName:item.EmployeeName,
                                cad2:item.cad2,
                                cadAvg2:item.cadAvg2,
                                cadKakou2:item.cadKakou2,
                                BranchCode:item.BranchCode,
                                BranchName:item.BranchName,
                                SalesOfficeCode:item.SalesOfficeCode,
                                SalesOfficeName:item.SalesOfficeName,
                                rank:ctr2,
                                KakouIrai:item.KakouIrai
                            })
                            return total
                        }
                    },[])
                }
                else if(this.tableSelected =='CAD依頼数(一般プラン+i-Smile/i-Palette/HUGme)'){
                    // this.items=[]
                    this.items3  = this.table4Hold.filter((item)=>{
                        if( item.cadKakou3 >= this.minimumKakou){
                            return {
                                    EmployeeCode:item.EmployeeCode,
                                    EmployeeName:item.EmployeeName,
                                    cad3:item.cad3,
                                    cadAvg3:item.cadAvg3,
                                    cadKakou3:item.cadKakou3,
                                    BranchCode:item.BranchCode,
                                    BranchName:item.BranchName,
                                    SalesOfficeCode:item.SalesOfficeCode,
                                    SalesOfficeName:item.SalesOfficeName,
                                    KakouIrai:item.KakouIrai
                            }
                        }
                    })
                    this.items3.sort((a,b)=>{
                            return a.cadAvg3 - b.cadAvg3
                    })
                    let ctr3 = 0 
                    this.items3= this.items3.reduce((total,item,i)=>{
                            let index = total.findIndex(x => x.cadAvg3 == item.cadAvg3)
                                if(index > -1){
                                    total.push({
                                    EmployeeCode:item.EmployeeCode,
                                    EmployeeName:item.EmployeeName,
                                    cad3:item.cad3,
                                    cadAvg3:item.cadAvg3,
                                    cadKakou3:item.cadKakou3,
                                    BranchCode:item.BranchCode,
                                    BranchName:item.BranchName,
                                    SalesOfficeCode:item.SalesOfficeCode,
                                    SalesOfficeName:item.SalesOfficeName,
                                    rank:ctr3,
                                    KakouIrai:item.KakouIrai
                                    })
                                    return total
                                }
                                else{
                                   
                            if(ctr3 <= 0){
                                ctr3=ctr3+(i+1)
                            }
                            else{
                                ctr3=i+1
                            }
                            total.push({
                                EmployeeCode:item.EmployeeCode,
                                    EmployeeName:item.EmployeeName,
                                    cad3:item.cad3,
                                    cadAvg3:item.cadAvg3,
                                    cadKakou3:item.cadKakou3,
                                    BranchCode:item.BranchCode,
                                    BranchName:item.BranchName,
                                    SalesOfficeCode:item.SalesOfficeCode,
                                    SalesOfficeName:item.SalesOfficeName,
                                    rank:ctr3,
                                    KakouIrai:item.KakouIrai
                            })
                            return total
                        }
                    },[])

                }
            },
            generateCsv(){
                let headers=[]
                let title =''
                let item = []
                if(this.tableSelected=='追加変更数'){
                    if(this.typeofSelect =='Planners'){
                        headers=[
                        {text: "順位", value: "rank"},
                        {text: "加工依頼数 ", value: "KakouIrai"},
                        {text: "追加変更数", value: "TH"},
                        {text: "1件あたりの追加変更数", value: "avg"},
                        {text: "営業所名", value: "SalesOfficeName"},
                        {text: "展示場名", value: "BranchName"},
                        {text: "設計担当者名", value: "EmployeeName"},
                        ]
                    }
                    else if(this.typeofSelect =='SalesOffices'){
                        headers=[
                            {text: "順位", value: "rank"},
                            {text: "加工依頼数 ", value: "KakouIrai"},
                            {text: "追加変更数", value: "TH"},
                            {text: "1件あたりの追加変更数", value: "avg"},
                            {text: "営業所名", value: "SalesOfficeName"},
                        ]

                       
                    }
                    title = '追加変更数'
                    item = this.NationWideArr.filter(item=>{
                      let obj =  {
                            EmployeeCode: item.EmployeeCode,
                            EmployeeName:item.EmployeeName,
                            TH: item.TH,
                            KakouIrai: item.KakouIrai,
                            avg: item.avg,
                            BranchCode: item.BranchCode,
                            BranchName: item.BranchName,
                            SalesOfficeCode: item.SalesOfficeCode,
                            SalesOfficeName: item.SalesOfficeName,
                            rank: item.rank
                        }
                       return obj
                    })
                }
                else if(this.tableSelected=='CAD依頼数(一般プラン+i-Smile/i-Palette/HUGme)'){
                    if(this.typeofSelect =='Planners'){
                        headers=[
                            {text: "順位", value: "rank"},
                            {text: "加工依頼数 ", value: "cadKakou3"},
                            {text: "CAD依頼総数", value: "cad3"},
                            {text: "1件あたりのCAD依頼数", value: "cadAvg3"},
                            {text: "営業所名", value: "SalesOfficeName"},
                            {text: "展示場名", value: "BranchName"},
                            {text: "設計担当者名", value: "EmployeeName"},
                        ]
                    }
                    else if(this.typeofSelect =='SalesOffices'){
                        headers=[
                            {text: "順位", value: "rank"},
                            {text: "加工依頼数 ", value: "cadKakou3"},
                            {text: "CAD依頼総数", value: "cad3"},
                            {text: "1件あたりのCAD依頼数", value: "cadAvg3"},
                            {text: "営業所名", value: "SalesOfficeName"},
                        ]
                    }
                    title = 'CAD依頼数(一般プラン+i-Smile/i-Palette/HUGme)'
                    item = this.items3.filter(item=>{
                        if(item.cadAvg3=='NaN' ){
                            item.cadAvg3='0.0'
                        }
                      let obj =  {
                            EmployeeCode: item.EmployeeCode,
                            EmployeeName:item.EmployeeName,
                            cad3:item.cad3,
                            cadAvg3:item.cadAvg3,
                            cadKakou3:item.cadKakou3,
                            BranchCode: item.BranchCode,
                            BranchName: item.BranchName,
                            SalesOfficeCode: item.SalesOfficeCode,
                            SalesOfficeName: item.SalesOfficeName,
                            rank: item.rank
                        }
                       return obj
                    })
                }
                else if(this.tableSelected=='CAD依頼数（一般プラン）'){
                    if(this.typeofSelect =='Planners'){
                        headers=[
                            {text: "順位", value: "rank"},
                            {text: "加工依頼数 ", value: "cadKakou"},
                            {text: "CAD依頼総数", value: "cad"},
                            {text: "1件あたりのCAD依頼数", value: "cadAvg"},
                            {text: "営業所名", value: "SalesOfficeName"},
                            {text: "展示場名", value: "BranchName"},
                            {text: "設計担当者名", value: "EmployeeName"},
                        ]
                    }
                    else if(this.typeofSelect =='SalesOffices'){
                        headers=[
                            {text: "順位", value: "rank"},
                            {text: "加工依頼数 ", value: "cadKakou"},
                            {text: "CAD依頼総数", value: "cad"},
                            {text: "1件あたりのCAD依頼数", value: "cadAvg"},
                            {text: "営業所名", value: "SalesOfficeName"},
                        ]
                    }
                
                    title = 'CAD依頼数（一般プラン）'
                    item = this.items.filter(item=>{
                        if(item.cadAvg=='NaN' ){
                            item.cadAvg='0.0'
                        }
                      let obj =  {
                            EmployeeCode: item.EmployeeCode,
                            EmployeeName:item.EmployeeName,
                            cad:item.cad,
                            cadAvg:  item.cadAvg ,
                            cadKakou:item.cadKakou,
                            BranchCode: item.BranchCode,
                            BranchName: item.BranchName,
                            SalesOfficeCode: item.SalesOfficeCode,
                            SalesOfficeName: item.SalesOfficeName,
                            rank: item.rank
                        }
                       return obj
                    })
                }
                else if(this.tableSelected=='CAD依頼数(i-smile/i-palette/HUGme)'){
                    if(this.typeofSelect =='Planners'){
                        headers=[
                            {text: "順位", value: "rank"},
                            {text: "加工依頼数 ", value: "cadKakou2"},
                            {text: "CAD依頼総数", value: "cad2"},
                            {text: "1件あたりのCAD依頼数", value: "cadAvg2"},
                            {text: "営業所名", value: "SalesOfficeName"},
                            {text: "展示場名", value: "BranchName"},
                            {text: "設計担当者名", value: "EmployeeName"},
                        ]
                    }
                    else if(this.typeofSelect =='SalesOffices'){
                        headers=[
                            {text: "順位", value: "rank"},
                            {text: "加工依頼数 ", value: "cadKakou2"},
                            {text: "CAD依頼総数", value: "cad2"},
                            {text: "1件あたりのCAD依頼数", value: "cadAvg2"},
                            {text: "営業所名", value: "SalesOfficeName"},
                        ]
                    }
                    title = 'CAD依頼数(i-smile/i-palette/HUGme)'
                    item = this.items2.filter(item=>{
                        if(item.cadAvg2=='NaN' ){
                            item.cadAvg2='0.0'
                        }
                      let obj =  {
                            EmployeeCode: item.EmployeeCode,
                            EmployeeName:item.EmployeeName,
                            cad2:item.cad2,
                            cadAvg2:item.cadAvg2,
                            cadKakou2:item.cadKakou2,
                            BranchCode: item.BranchCode,
                            BranchName: item.BranchName,
                            SalesOfficeCode: item.SalesOfficeCode,
                            SalesOfficeName: item.SalesOfficeName,
                            rank: item.rank
                        }
                       return obj
                    })
                }



                let csv = ""
                      for(let header of headers){
                        csv += header.text + ","
                      }
                      csv+="\n"
                    for(let i =0;i<=item.length-1;i++){
                            for(let header of headers){
                                csv+=item[i][header.value] + ","
                            }
                            csv+="\n"
                    }
                    csv+="\n"
                  let filename = `${title}.csv`
                  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    if (navigator.msSaveBlob) { // IE 10+
                        navigator.msSaveBlob(blob, filename);
                    } 
                    else {
                        var link = document.createElement("a");
                        if (link.download !== undefined) { // feature detection
                            // Browsers that support HTML5 download attribute
                            var url = URL.createObjectURL(blob);
                            link.setAttribute("href", url);
                            link.setAttribute("download", filename);
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }
            },
            generateXL(){
                this.newArr=[]
                this.headers={}
                this.title=''
                if(this.tableSelected=='追加変更数'){
                    this.title='追加変更数'
                    this.headers={
                        "順位": "rank",
                        "加工依頼数 ":"KakouIrai",
                        "追加変更数":"TH",
                        "1件あたりの追加変更数": "avg",
                        "営業所名":"SalesOfficeName",
                        "展示場名":"BranchName",
                        "設計担当者名":"EmployeeName"
                    }
                    this.newArr = this.NationWideArr
                }
                else if(this.tableSelected=='CAD依頼数(一般プラン+i-Smile/i-Palette/HUGme)'){
                    this.title='CAD依頼数(一般プラン+i-Smile/i-Palette/HUGme)'
                    this.headers={
                        "順位": "rank",
                        "加工依頼数 ":"cadKakou3",
                        "CAD依頼総数":"cad3",
                        "1件あたりのCAD依頼数": "cadAvg3",
                        "営業所名":"SalesOfficeName",
                        "展示場名":"BranchName",
                        "設計担当者名":"EmployeeName"
                    }
                    this.newArr = this.items3.filter(x=>{
                        if(x.cadAvg3 =='NaN'){
                            x.cadAvg3 ='0.0'
                        } 
                        return x
                    })
                }
                else if(this.tableSelected=='CAD依頼数（一般プラン）'){
                    this.title='CAD依頼数（一般プラン）'
                    this.headers={
                        "順位": "rank",
                        "加工依頼数 ":"cadKakou",
                        "CAD依頼総数":"cad",
                        "1件あたりのCAD依頼数": "cadAvg",
                        "営業所名":"SalesOfficeName",
                        "展示場名":"BranchName",
                        "設計担当者名":"EmployeeName"
                    }
                    this.newArr = this.items.filter(x=>{
                        if(x.cadAvg =='NaN'){
                            x.cadAvg ='0.0'
                        } 
                        return x
                    })
                }
                else if(this.tableSelected=='CAD依頼数(i-smile/i-palette/HUGme)'){
                    this.title='CAD依頼数(i-smile/i-palette/HUGme)'
                    this.headers={
                        "順位": "rank",
                        "加工依頼数 ":"cadKakou2",
                        "CAD依頼総数":"cad2",
                        "1件あたりのCAD依頼数": "cadAvg2",
                        "営業所名":"SalesOfficeName",
                        "展示場名":"BranchName",
                        "設計担当者名":"EmployeeName"
                    }
                    this.newArr = this.items2.filter(x=>{
                        if(x.cadAvg2 =='NaN'){
                            x.cadAvg2 ='0.0'
                        } 
                        return x
                    })
                }
            }
        },
        computed: {
            monthRestriction() {
                return moment(this.plannerCodeRange.fromDate).add(12,'months').endOf('month').format('YYYY-MM-DD')
            }
        },
        mounted () {
            if(this.userInfo.User_Category=='GC User' || this.userInfo.User_Category=='GC' || this.userInfo.User_Category=='gc' || this.userInfo.User_Category=='gc User'){
                axios.get(`${this.aws}getSalesOffices`,this.apiKey).then(r=>{
                    this.SalesOffice= r.data.filter(x=>{
                        if(x.EmployeeCode == this.userInfo.Emp_code){
                            return x
                        }
                    })
                })
            }     
        },
  
    }
</script>

<style lang="scss" scoped>

.TableStyle {
    border: 1px solid gray;
  }
.center {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}


.cnter{
    position:absolute;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>

